import sanitizeHtml from "sanitize-html";

import cx from "apps/website/utils/misc/cx";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

interface IHighlightTextWithHTML {
  text: string;
  theme?: Theme;
  display?: "inline" | "inline-block";
  rotate?: boolean;
}

/**
 * Processes a text string to apply highlighting using HTML.
 * @param {string} text The input text string.
 * @param {Theme} theme The theme to apply to the highlighted text.
 * @returns {string} The processed HTML-safe string with highlights.
 * @notes The function uses the backtick character (`) as the default indicator to toggle highlighting.
 */

const highlightTextWithHTML = ({
  text,
  theme = "brand",
  display = "inline",
  rotate = false,
}: IHighlightTextWithHTML): string => {
  const sanitizedText = sanitizeHtml(text);
  const highlightIndicator = "`";

  const splitText = sanitizedText.split(highlightIndicator);

  // If the text does not contain the highlight indicator, return the original text.
  // Highlighted text will always be at index 1 therefore will always be odd.
  return splitText
    .map((segment, index) => {
      // If the text is not highlighted, return the text with the relative z-10 class.
      // This is to ensure that the text is always on top of the highlighted text.
      if (index % 2 === 0) {
        return `<span class="relative z-10">${segment}</span>`;
      }
      // If the text is highlighted, return the text with the highlight class.
      const highlightClass = cx(
        themeRootClassMap[theme],
        display,
        "relative z-0",
        rotate && display === "inline-block" && "-rotate-1",
      );

      return `<span class='${highlightClass}'><span class="relative z-10">${segment}</span></span>`;
    })
    .join("");
};

export default highlightTextWithHTML;
