import { FC, PropsWithChildren } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import TextTitle from "apps/website/components/base/Text/TextTitle/TextTitle";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import highlightTextWithHTML from "apps/website/utils/website/highlightTextWithHTML";
import { Theme } from "apps/website/maps/Theme.map";
import Container from "apps/website/components/layout/Container/Container";

import { TTabType } from "../../shared/types";
import { getNextTabKey, getPreviousTabKey } from "../../shared/tabUtils";
import { pageableTabButtonClasses } from "../../shared/constants";

interface IPageableNavigationProps {
  title: string;
  subtitle?: string;
  theme?: Theme;
  tabs: TTabType[];
  activeTab: string;
  onTabChange: (key: string | undefined) => void;
  "data-testid"?: string;
}

export const PageableNavigation: FC<PropsWithChildren<IPageableNavigationProps>> = ({
  title,
  subtitle,
  theme,
  tabs,
  activeTab,
  onTabChange,
  children,
  "data-testid": testId,
}) => (
  <Container>
    <Grid className="w-full" data-testid={testId}>
      <Column direction="row" align="center" justify="center" className="space-x-4">
        <div data-theme={!getPreviousTabKey(tabs, activeTab) ? "disabled" : "dark"}>
          <IconButton
            icon="chevronLeft"
            label="Previous tab"
            onClick={() => onTabChange(getPreviousTabKey(tabs, activeTab))}
            buttonClassName={pageableTabButtonClasses}
            disabled={!getPreviousTabKey(tabs, activeTab)}
            color={!getPreviousTabKey(tabs, activeTab) ? "disabled" : "default"}
            size="xsmall"
            aria-label="Previous tab"
            data-testid="prev-tab-button"
          />
        </div>
        <div className="text-center">
          <TextTitle
            tag="span"
            whitespace="preWrap"
            align="center"
            data-testid="pageable-title"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: highlightTextWithHTML({
                  text: title,
                  display: "inline-block",
                  theme: theme || "brand",
                  rotate: true,
                }),
              }}
              data-testid="pageable-title-content"
              data-theme={theme || "brand"}
            />
          </TextTitle>
          <div className="h-[28px] flex items-center justify-center">
            { subtitle && (
              <TextSubtitle size={2} className="uppercase" align="center">
                { subtitle }
              </TextSubtitle>
            ) }
          </div>

        </div>
        <div data-theme={!getNextTabKey(tabs, activeTab) ? "disabled" : "dark"}>
          <IconButton
            icon="chevronRight"
            label="Next tab"
            onClick={() => onTabChange(getNextTabKey(tabs, activeTab))}
            buttonClassName={pageableTabButtonClasses}
            disabled={!getNextTabKey(tabs, activeTab)}
            color={!getNextTabKey(tabs, activeTab) ? "disabled" : "default"}
            size="xsmall"
            aria-label="Next tab"
            data-testid="next-tab-button"
          />
        </div>
      </Column>
      <Column data-testid="pageable-content">
        { children }
      </Column>
    </Grid>
  </Container>
);
