import { FC } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import {
  pseudoThemeRootClassMap,
  Theme,
  themeRootClassMap,
} from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import TestimonialReviewCarousel from
  "apps/website/components/feature/Carousel/TestimonialReviewCarousel/TestimonialReviewCarousel";
import {
  ITestimonialReview,
} from "apps/website/components/feature/Review/TestimonialReview/TestimonialReview";

export interface ISectionTestimonialCarousel {
  title?: string;
  items: ITestimonialReview[],
  theme?: Theme
}

const SectionTestimonialCarousel: FC<ISectionTestimonialCarousel> = ({ title, items, theme = "dark" }) => (
  <Section component="SectionTestimonialCarousel" theme={theme} className="overflow-hidden">
    <Container>
      <Grid gap="lg">
        <>
          { title && (
            <Column
              spans={{ lg: 4 }}
              className={`relative z-10 respect-index before:absolute before:right-0 before:top-0 before:-translate-y-1/2 before:translate-x-6  before:h-[200%] before:w-screen ${themeRootClassMap[theme]} ${pseudoThemeRootClassMap[theme]}`}
            >
              <Grid className="relative h-full">
                <Column justify="center" align={{ default: "center", lg: "start" }}>
                  <Text tag="h2" size={legacySizeCollectionMap.titleMd} display="subtitle" align={{ default: "center", lg: "default" }}>{ title }</Text>
                </Column>
              </Grid>
            </Column>
          ) }
          <Column spans={{ lg: title ? 8 : 12 }}>
            <TestimonialReviewCarousel items={items} theme={theme} />
          </Column>
        </>
      </Grid>
    </Container>
  </Section>
);

export default SectionTestimonialCarousel;
