import { ReactNode } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import {
  IProductOverviewTabProps,
} from "../ProductOverviewTabs/ProductOverviewTab/ProductOverviewTab";

import { IBaseTab, IPageableTab, TTabType } from "./types";

// Pageable Tab Utils
export function isPageableTab(o: TTabType): o is IPageableTab {
  return typeof (o as IPageableTab).Panel === "object" && !!(o as IPageableTab).Panel.title;
}

export function getActiveTabTheme(tab: TTabType): Theme {
  if (isPageableTab(tab) && tab.Panel.theme) {
    return tab.Panel.theme;
  }
  return "brand";
}

export function getNextTabKey(tabs: TTabType[], activeTab: string): string | undefined {
  const currentIndex = tabs.findIndex((tab) => tab.key === activeTab);
  const nextIndex = currentIndex + 1;
  if (nextIndex >= tabs.length) return tabs[0].key;
  return tabs[nextIndex]?.key;
}

export function getPreviousTabKey(tabs: TTabType[], activeTab: string): string | undefined {
  const currentIndex = tabs.findIndex((tab) => tab.key === activeTab);
  const previousIndex = currentIndex - 1;
  if (previousIndex < 0) return tabs[tabs.length - 1].key;
  return tabs[previousIndex].key;
}

// Product Overview Tab Utils
export function isProductOverviewTab(tab: IBaseTab): tab is IProductOverviewTabProps {
  return tab.tabType === "productOverview";
}

// Button Utils
export function isDefaultButton(button: string | ReactNode): boolean {
  return typeof button === "string";
}
