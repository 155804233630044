"use client";

import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay } from "swiper";

import { scrollingBannerMessages } from "./constants";
import CircledCheckSVG from "./CircledCheckSVG";

const ScrollingBanner: React.FC<{ linkingId: string }> = ({ linkingId }) => (
  <div className="bg-brand py-1 px-1.5 flex gap-x-4 col-span-12" data-component="ScrollingBanner" data-linkingid={linkingId}>
    <Swiper
      modules={[ Autoplay ]}
      className="flex"
      slidesPerView="auto"
      spaceBetween={16}
      loop
      speed={10000}
      autoplay={{ delay: 1, pauseOnMouseEnter: true, disableOnInteraction: true }}>
      { scrollingBannerMessages.map((message) => (
        <SwiperSlide key={message} style={{ flexShrink: 0, display: "block", width: "auto" }}>
          <p className="flex font-bold text-sm items-center whitespace-nowrap h-6 mt-0.5">
            <CircledCheckSVG className="mr-1 -mt-1" />
            <span>
              { message }
            </span>
          </p>
        </SwiperSlide>
      )) }
    </Swiper>

  </div>

);

export default ScrollingBanner;
