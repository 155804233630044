"use client";

import { FC, useEffect } from "react";

import {
  loadMentionMeConversionMessage,
} from "../../../utils/mentionme/loadMentionMe";

export const MentionMeConversionMessage: FC = () => {
  useEffect(() => loadMentionMeConversionMessage({}), []);
  return (<div id={"mmWrapper"}></div>);
};
