import { useRouter } from "next/navigation";
import { FC } from "react";

import { DisplayState } from "@/constants/state";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDatadog } from "@auth/client-sdk-react";
import Container from "apps/website/components/layout/Container/Container";
import Button from "apps/website/components/base/Button/Button";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

export interface ApplyDiscountButtonProps {
  discountPercentage?: string;
  discountCode?: string;
  setFormState: (state: DisplayState) => void;
}

const PreCancellationApplyDiscountButton: FC<ApplyDiscountButtonProps> =
({ discountPercentage, discountCode, setFormState }) => {

  const api = useAPI();
  const router = useRouter();
  const datadog = useDatadog();
  const { chainQueryParamsToUrl } = useQueryParams();

  const applyDiscount = async () => {

    try {
      const result = await api.Discounts.applyDiscount(discountCode!);

      if (result) {
        void router.push(chainQueryParamsToUrl(`/discounts/applied?discountPercentage=${discountPercentage}&delayMessage=true`));
      }

      if (!result) {
        throw new Error("Discount not applied");

      }
    } catch (error) {
      datadog.logger.error("Error applying discount", { eventName: "ErrorApplyingDiscount" }, error as Error);
      setFormState(DisplayState.ERROR);
    }
  };

  return (
    <> { discountPercentage &&
      <Container size="sm" padding="none">
        <div className="flex flex-col items-center justify-center gap-y-4">
          <Button onClick={applyDiscount} color="secondary">
            { `Get ${discountPercentage}% OFF` }
          </Button>
        </div>
      </Container>
    }
    </>
  );
};

export default PreCancellationApplyDiscountButton;
