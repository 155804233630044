import { useRouter } from "next/navigation";
import { FC, useState } from "react";

import { DisplayState } from "@/constants/state";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDatadog } from "@auth/client-sdk-react";

import Button from "../../base/Button/Button";
import Container from "../../layout/Container/Container";

export interface ApplyDiscountButtonProps {
  discountPercentage?: string;
  discountCode?: string;
  catName?: string;
  catSex?: string;
}

const ApplyDiscountButton: FC<ApplyDiscountButtonProps> = ({ discountPercentage, discountCode, catName = "your cat", catSex = "their" }) => {

  const [ buttonState, setButtonState ] = useState<DisplayState>(
    DisplayState.READY,
  );

  const api = useAPI();
  const router = useRouter();
  const datadog = useDatadog();

  const applyDiscount = async () => {
    setButtonState(DisplayState.LOADING);

    try {
      const result = await api.Discounts.applyDiscount(discountCode!);

      if (result) {
        void router.push(`/discounts/applied?cat=${catName}&discountPercentage=${discountPercentage}&catSex=${catSex}`);
      }

      if (!result) {
        setButtonState(DisplayState.ERROR);
        void router.push(`/discounts/failed?cat=${catName}&discountPercentage=${discountPercentage}&catSex=${catSex}&discountCode=${discountCode}`);
      }
    } catch (error) {
      datadog.logger.error("Error applying discount", { eventName: "ErrorApplyingDiscount" }, error as Error);
      setButtonState(DisplayState.ERROR);
      void router.push(`/discounts/failed?cat=${catName}&discountPercentage=${discountPercentage}&catSex=${catSex}&discountCode=${discountCode}`);
    }
  };

  return (
    <> { discountPercentage &&
      <Container size="sm" padding="none">
        <div className="flex flex-col items-center justify-center gap-y-4">
          <Button className="w-[280px]" onClick={applyDiscount} state={buttonState}>
            { `Get ${discountPercentage}% OFF` }
          </Button>
        </div>
      </Container>
    }
    </>
  );
};

export default ApplyDiscountButton;
