"use client";

import { FC, useEffect } from "react";

import { loadMentionMeLanding } from "../../../utils/mentionme/loadMentionMe";

import "./mentionMeLandingStyle.css";

export const MentionMeLanding: FC = () => {
  useEffect(() => {
    const { searchParams } = new URL(`${document.location}`);
    return loadMentionMeLanding({
      firstname: searchParams.get("firstname"),
      surname: searchParams.get("surname"),
      fullname: searchParams.get("fullname"),
      email: searchParams.get("email"),
      customer_id: searchParams.get("customer_id"),
      username: searchParams.get("username"),
      phone_number: searchParams.get("phone_number"),
      custom_field: searchParams.get("custom_field"),
      custom_share_field: searchParams.get("custom_share_field"),
      calltoactionurl: searchParams.get("calltoactionurl"),
      segment: searchParams.get("segment"),
    });
  }, []);
  return (<div id={"mmWrapper"} className="lg:mb-8 mb-4"></div>);
};
