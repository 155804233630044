import {
  ITestimonialReview,
} from "apps/website/components/feature/Review/TestimonialReview/TestimonialReview";

export const testimonialCarouselItems: ITestimonialReview[] = [
  {
    id: "1",
    stars: 5,
    starStyle: "trustpilot",
    title: "I struggled to get my cat to eat Katkin. But I am SO glad that I stuck with it — now she shouts for her food!",
    body: "",
    author: "Susan + Rosie",
    image: { src: "https://a.storyblok.com/f/176726/1000x1270/febf063328/katkin_221011_03_lifestylecats3-100-1.png", width: 1000, height: 1270 },
  },
  {
    id: "2",
    stars: 5,
    starStyle: "trustpilot",
    title: "Both cats were wary ... but are now loving meal times ... The cats are happier and have glossy coats.",
    body: "",
    author: "Kelly",
    image: { src: "https://a.storyblok.com/f/176726/1001x1270/783fe45af2/katkin_221011_03_feeding1-102_extended-2-1.png", width: 1000, height: 1270 },
  },
  {
    id: "3",
    stars: 5,
    starStyle: "trustpilot",
    title: "Perseverance  is key. Now, George is not only enjoying his food but his health has improved enormously.",
    body: "",
    author: "Beth + George",
    image: { src: "https://a.storyblok.com/f/176726/1000x1270/6e77c52409/katkin_221011_04_feeding1-160-1.png", width: 1000, height: 1270 },
  },
  {
    id: "4",
    stars: 5,
    starStyle: "trustpilot",
    title: "After a slow start... he's improved significantly... today is the first time I've seen him play in ages.",
    body: "",
    author: "Jo",
    image: { src: "https://a.storyblok.com/f/176726/1000x1271/1d59a13ab1/screenshot-2023-02-07-at-09-29-1.png", width: 1000, height: 1270 },
  },
];
