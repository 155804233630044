"use client";

import { FC, useState } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Tag from "apps/website/components/base/Tag/Tag";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import { Theme } from "apps/website/maps/Theme.map";
import { DisplayState } from "@/constants/state";
import ConditionalCheckListItem from "apps/website/components/feature/ConditionalCheckList/ConditionalCheckListItem/ConditionalCheckListItem";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Icon from "apps/website/components/base/Icon/Icon";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import { preCancellationDiscountCode } from "@/discounts-utils";

import Spacer from "../../../../layout/Spacer/Spacer";
import Column from "../../../../layout/Column/Column";
import Container from "../../../../layout/Container/Container";
import Section from "../../../../layout/Section/Section";

import PreCancellationApplyDiscountButton from "./SectionPreCancellationDiscountButton";
import { ErrorMessage } from "./Error";

export interface ISectionPreCancellationDiscountProps {
  theme?: Theme;
  image: IImageProps;
  catName?: string;
}

const ListRow = ({ text }: { text: string }) => (
  <ListItem component={ConditionalCheckListItem.name} className="mb-4">
    <div className="flex items-center">
      <div className="rounded-full bg-green mr-2 w-6 h-6">
        <Icon icon="tickHeavy" size="xsmall"/>
      </div>

      <TextSubtitle size={3} tag="span" color="default" >
        { text }
      </TextSubtitle>
    </div>
  </ListItem>
);
const SectionPreCancellationDiscount: FC<ISectionPreCancellationDiscountProps> =
({ theme, image, catName }) => {

  const [ formState, setFormState ] = useState<DisplayState>(
    DisplayState.LOADING,
  );

  const upperText =
  `We'd hate for ${catName} to miss out on the health benefits of feeding fresh.
   So here's 30% off to keep you going - because ${catName} deserves the best.\nPlus, with KatKin, you always get:`;

  return (

    <Section component={SectionPreCancellationDiscount.name} theme={theme}>
      <Container>

        <Grid>
          <Column align="center" justify="center">
            <div className={"w-24 h-24"}>
              <Image
                image={image}
                alt="image"
              />
            </div>
            <Spacer size="sm"></Spacer>
            <Tag theme="dark" className="max-w-[300px]" whitespace="preWrap">
              <TextSubtitle size={1}>WANT 30% OFF?</TextSubtitle>
            </Tag>
            <Spacer size="lg"></Spacer>
            <TextBody weight="normal" size={1} align={"center"} whitespace="preWrap">{ upperText }</TextBody>
            <Spacer size="md"></Spacer>
          </Column>

          <Column align="center" className="flex-start" >
            <div>
              <ListRow text="100% human-quality ingredients"/>
              <ListRow text="Swap recipes, delay or cancel at any time"/>
              <ListRow text="Get advice from our team of cat experts"/>
            </div>
          </Column>

        </Grid>

        <Spacer size="md"></Spacer>

        <PreCancellationApplyDiscountButton
          discountPercentage={"30"}
          discountCode={preCancellationDiscountCode}
          setFormState={setFormState}
        />

        { formState === DisplayState.ERROR && <ErrorMessage data-testid="error-message"/> }
        <Spacer size="xl"></Spacer>

        <Column align="center">

          <TextSubtitle size={4} align="center">
          Not ready for your next box? <br/>
You can delay after <br/>
 your discount is applied.
          </TextSubtitle>

        </Column>

      </Container>

    </Section>
  );
};

export default SectionPreCancellationDiscount;
