"use client";

import { FC, useEffect } from "react";
import clsx from "clsx";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import useFocusTrap from "apps/website/hooks/useFocusTrap";
import useModal from "apps/website/hooks/useModal";

import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";
import Modal from "../../feature/Modal/Modal";
import Button from "../../base/Button/Button";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";
import Table from "../../feature/Table/Table";
import Image, { IImageProps } from "../../base/Image/Image";
import TextBody from "../../base/Text/TextBody/TextBody";
import Logo from "../../base/Icon/Logo/Logo";
import Spacer from "../../layout/Spacer/Spacer";
import Container from "../../layout/Container/Container";
import Text from "../../base/Text/Text";

interface IComparisonModalTableHeader {
  title: string;
  image?: IImageProps;
}

interface IComparisonModalTableKatKinHeader extends IComparisonModalTableHeader {
  withLogo?: boolean;
}

export type TIComparisonModalTableRowCellIcon = "TICK" | "CROSS";

interface IComparisonModalTableRowCell {
  icon: TIComparisonModalTableRowCellIcon;
  body: string;
}

interface IComparisonModalTableHeaders {
  competitor: IComparisonModalTableHeader
  katkin: IComparisonModalTableKatKinHeader;
}

interface IComparisonModalTableRow {
  competitor: IComparisonModalTableRowCell
  katkin: IComparisonModalTableRowCell;
}

interface IComparisonModalTable {
  headers: IComparisonModalTableHeaders;
  rows: IComparisonModalTableRow[];
}

export interface ISectionComparisonModalProps {
  theme?: Theme;
  ctaText: string;
  modalTitle: string;
  table: IComparisonModalTable;
}

const CELL_ICONS: Record<TIComparisonModalTableRowCellIcon, IImageProps> = {
  TICK: {
    src: "/images/dev/components/SectionComparisonModal/tick.webp",
    width: 100,
    height: 100,
  },
  CROSS: {
    src: "/images/dev/components/SectionComparisonModal/cross.webp",
    width: 100,
    height: 100,
  },
};

const SectionComparisonModal: FC<ISectionComparisonModalProps> = ({ theme = "default", ctaText, modalTitle, table }) => {
  const { modalId, modalTitleId, modalButtonId, modalIsOpen, setModalIsOpen } = useModal(false);
  const { initFocusTrap, destroyFocusTrap } = useFocusTrap();

  useEffect(() => {
    if (modalIsOpen) {
      initFocusTrap(modalId);
    } else {
      destroyFocusTrap(modalButtonId);
    }
  }, [ modalIsOpen ]);

  return (
    <Section component={SectionComparisonModal.name} theme={theme} size={getSectionSize(theme)}>
      <Container>
        <Grid>
          <Column justify="center" align="center">
            <Button design="shimmer" id={ modalButtonId } onClick={ () => setModalIsOpen(true) }>{ ctaText }</Button>
          </Column>
        </Grid>
      </Container>
      <Modal
        component="SectionComparisonModalWindow"
        id={ modalId }
        isOpen={ modalIsOpen }
        onClose={ () => setModalIsOpen(false)}
        modalTitleId={ modalTitleId }
        theme="brand"
        borderRadius="rounded"
        size="md"
      >
        <div className="p-8">
          <Grid>
            <Column align="center">
              <TextSubtitle size={ 2 } data-testid="SectionComparisonModalWindow__title">{ modalTitle }</TextSubtitle>
              <Spacer size="xl"/>
              <div className="w-full relative">
                <div
                  className={ clsx(
                    "h-full w-[calc(50%+1rem)] absolute right-0",
                    themeRootClassMap.dark,
                  ) }></div>
                <div
                  className={ clsx(
                    "h-[calc(100%+1rem)] w-1/2 absolute left-0 border-black border-4 border-solid scale-[1.05] -rotate-1 backface-hidden outline-black outline",
                    themeRootClassMap.light,
                  ) }></div>
                <Table className="pt-8 w-full relative z-20">
                  <tr>

                    <th className="text-center relative w-1/2">
                      <div className="relative pt-[35px] lg:pt-[45px]">
                        <div
                          data-theme="brand"
                          data-testid="SectionComparisonModalWindow__katkinTitle"
                          className={clsx(
                            themeRootClassMap.brand,
                            "-rotate-1 py-1 px-2 lg:py-2 lg:px-4 w-fit inline-block",
                          )}
                        >
                          { /** Escape hatch */ }
                          <Text tag="span" size={ { default: "md", lg: "2xl" } } display="title">{ table.headers.katkin.title }</Text>
                        </div>
                        { table.headers.katkin.withLogo ? (
                          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[120px] lg:max-w-[160px] inline-block"><Logo /></div>
                        ) : null }
                      </div>

                      { table.headers.katkin.image ? (
                        <div className="inline-block max-w-[180px] lg:max-w-[250px]">
                          <Image
                            image={ table.headers.katkin.image }
                            alt={ table.headers.katkin.title }
                            data-testid="SectionComparisonModalWindow__katkinImage"
                          />
                        </div>
                      ) : null }
                    </th>
                    <th data-theme="dark" className={ "text-center w-1/2"} >
                      { table.headers.katkin.withLogo ? (
                        <Spacer size="lg" />
                      ) : null }
                      <div data-testid="SectionComparisonModalWindow__competitorTitle">
                        { /** Escape hatch */ }
                        <Text tag="span" size={ { default: "md", lg: "2xl" } } display="title">{ table.headers.competitor.title }</Text>
                      </div>
                      { table.headers.competitor.image ? (
                        <div className="inline-block max-w-[180px] lg:max-w-[240px]">
                          <Image
                            image={ table.headers.competitor.image }
                            alt={ table.headers.competitor.title }
                            data-testid="SectionComparisonModalWindow__competitorImage"
                          />
                        </div>
                      ) : null }
                    </th>
                  </tr>
                  { table.rows.map((row, index) => (
                    <tr key={index}>

                      <td className={clsx(
                        "text-center p-2 lg:p-4",
                        index === 0 ? "pt-0 lg:pt-0" : null,
                        index === table.rows.length - 1 ? "relative" : null,
                      )}>
                        <div className="inline-block w-6 h-6 lg:w-10 lg:h-10">
                          <Image image={ CELL_ICONS[row.katkin.icon] } alt={ row.competitor.icon } className="inline-block" />
                        </div>
                        <TextBody
                          tag="div"
                          size={ 2 }
                          align="center"
                          data-testid={ `SectionComparisonModalWindow__row${index}katkinBody` }
                        >{ row.katkin.body }</TextBody>
                      </td>
                      <td
                        data-theme="dark"
                        className={clsx(
                          "text-center p-2 lg:p-4",
                          index === 0 ? "pt-0 lg:pt-0" : null,
                        )}
                      >
                        <div className="inline-block w-6 h-6 lg:w-10 lg:h-10">
                          <Image image={ CELL_ICONS[row.competitor.icon] } alt={ row.competitor.icon } />
                        </div>
                        <TextBody
                          tag="div"
                          size={ 2 }
                          align="center"
                          data-testid={ `SectionComparisonModalWindow__row${index}competitorBody` }
                        >{ row.competitor.body }</TextBody>
                      </td>
                    </tr>
                  )) }
                </Table>
              </div>
            </Column>
          </Grid>
          <Spacer size="xl" />
          <Grid>
            <Column justify="center" align="center">
              <Button onClick={ () => setModalIsOpen(false) }>Close</Button>
            </Column>
          </Grid>
          <Spacer size="xl" />
        </div>
      </Modal>
    </Section>
  );
};

export default SectionComparisonModal;
