import Link from "next/link";
import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Column from "apps/website/components/layout/Column/Column";
import {
  BreakpointSpan,
  Span,
} from "apps/website/components/layout/Column/Column.map";

export interface IReviewOverviewLink {
  id?: string;
  title: string;
  to?: string;
  logo: IImageProps;
  spans?: Span | BreakpointSpan;
}

const ReviewOverviewLink: FC<IReviewOverviewLink> = ({ title, to, logo, spans }) => (
  <Column component={ ReviewOverviewLink.name } tag="li" spans={ spans || { default: 6, lg: 3 }} justify="center" align="center">
    { to ? (
      <Link href={to} rel="noopener nofollow noreferrer" target="_blank">
        <Image image={logo} alt={title} />
      </Link>
    ) : (
      <Image image={logo} alt={title} />
    ) }
  </Column>
);

export default ReviewOverviewLink;
