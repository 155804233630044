"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";

import ShortMessageCarouselItem, {
  IShortMessageCarouselItem,
} from "./ShortMessageCarouselItem/ShortMessageCarouselItem";

export interface IShortMessageCarousel {
  items: IShortMessageCarouselItem[];
}

const ShortMessageCarousel: FC<IShortMessageCarousel> = ({ items }) => (
  <Carousel
    component="ShortMessageCarousel"
    showNavigation

  >
    { items.map((item, index) => (
      <SwiperSlide key={index} >
        <ShortMessageCarouselItem {...item} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default ShortMessageCarousel;
