import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import cx from "apps/website/utils/misc/cx";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Icon from "apps/website/components/base/Icon/Icon";

export interface IShortMessageCarouselItem {
  slideText: string;
  icon: IconSvg;
  theme: Theme;
}

const ShortMessageCarouselItem: FC< IShortMessageCarouselItem > = ({ icon, slideText, theme }) => (
  <div data-component={ShortMessageCarouselItem.name} data-theme={theme} className={cx(`h-full  ${themeRootClassMap[theme]} px-4 py-1`)}>
    <Grid gap="sm">
      <Column spans={9} justify="center" align="center">
        <TextSubtitle size={1}>{ slideText }</TextSubtitle>
      </Column>
      <Column spans={3} justify="center" align="center" >
        <Icon icon={icon} size="large"/>
      </Column>
    </Grid>
  </div>
);

export default ShortMessageCarouselItem;
