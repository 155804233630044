export type DiscountSign = "POSITIVE" | "NEGATIVE";

export interface IDiscount {
  type: "FIXED_REDUCTION" | "PERCENTAGE_REDUCTION" | "FIXED_ABSOLUTE" | undefined;
  value: number; // Behaviour of value should vary depending on the type of discount
}

const handleDiscountSign = (sign: DiscountSign): string => (sign === "NEGATIVE" ? "-" : "");

export function getDiscountAsString(discount: IDiscount, sign: DiscountSign = "POSITIVE"): string {
  if (discount.type === "PERCENTAGE_REDUCTION") return `${handleDiscountSign(sign)}${discount.value}%`;
  return `${handleDiscountSign(sign)}£${discount.value.toFixed(2)}`;
}

export const preCancellationDiscountCode = "CX2NDBOX_30";
