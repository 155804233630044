"use client";

import React, { FC, useMemo } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import {
  IShortMessageCarouselItem,
} from "apps/website/components/feature/Carousel/ShortMessageCarousel/ShortMessageCarouselItem/ShortMessageCarouselItem";
import ShortMessageCarousel from "apps/website/components/feature/Carousel/ShortMessageCarousel/ShortMessageCarousel";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import highlightTextWithHTML from "apps/website/utils/website/highlightTextWithHTML";
import { Theme } from "apps/website/maps/Theme.map";

export interface ISectionShortMessageCarousel {
  title?: string;
  subtitle?: string;
  items: IShortMessageCarouselItem[];
  theme?: Theme;
}

const SectionShortMessageCarousel: FC<ISectionShortMessageCarousel> = ({ title, subtitle, items, theme = "default" }) => {

  const highlightedSubtitle = useMemo(
    () => (subtitle ? highlightTextWithHTML({ text: subtitle }) : ""),
    [ subtitle ],
  );

  return (
    <Section component={ SectionShortMessageCarousel.name } theme={ theme } >
      <Container >
        <div className="pb-4">
          <TextSubtitle size={1} align={"center"}>{ title }</TextSubtitle>
          <TextSubtitle size={2} align={"center"}>  <span dangerouslySetInnerHTML={ { __html: highlightedSubtitle } }></span></TextSubtitle>
        </div>

        <ShortMessageCarousel items={items} />

      </Container>
    </Section>
  );
};

export default SectionShortMessageCarousel;
