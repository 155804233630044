import {
  AlertPrompt,
} from "apps/website/components/feature/AlertPrompt/AlertPrompt";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export const ErrorMessage = () => (
  <div>
    <Spacer />
    <AlertPrompt style="warning" title="SOMETHING WENT WRONG">Try again to claim the discount. </AlertPrompt>
  </div>
);
