"use client";

import {
  useState,
  FC,
  KeyboardEvent,
  useRef,
} from "react";

import useA11y from "apps/website/hooks/useA11y";
import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";

import TextTitle from "../../base/Text/TextTitle/TextTitle";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";

import { ITabs, TTabType } from "./shared/types";
import {
  isDefaultButton,
  isPageableTab,
  getActiveTabTheme,
} from "./shared/tabUtils";
import {
  PageableNavigation,
} from "./components/PageableNavigation/PageableNavigation";

const Tabs: FC<ITabs> = ({ tabs, defaultActiveTab, component }) => {
  const { UUID } = useA11y();
  const [ activeTab, setActiveTab ] = useState(defaultActiveTab || tabs[0].key);
  const tabButtons = useRef<HTMLButtonElement[]>([]);

  const isActiveTab = (key: string) => key === activeTab;
  const tabStateClasses = (key: string) => (isActiveTab(key) ? "flex" : "hidden");
  const getButtonId = (key: string) => `tabs-button-${UUID}-${key}`;
  const getContentId = (key: string) => `tabs-content-${UUID}-${key}`;

  const onButtonArrowLeftDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const index = Number((event.target as HTMLButtonElement).getAttribute("data-index"));
    if (index > 0) {
      const newIndex = index - 1;
      const newKey = tabButtons.current[newIndex];
      return newKey?.focus();
    }
  };

  const onButtonArrowRightDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const index = Number((event.target as HTMLButtonElement).getAttribute("data-index"));
    if (index < tabs.length) {
      const newIndex = index + 1;
      const newKey = tabButtons.current[newIndex];
      return newKey.focus();
    }
  };

  const onButtonKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (![ "ArrowLeft", "ArrowRight", "Tab" ].includes(event.key)) return;
    if (event.key === "ArrowLeft") return onButtonArrowLeftDown(event);
    if (event.key === "ArrowRight") return onButtonArrowRightDown(event);
  };

  const setActiveTabFromPanel = (key: string | undefined) => {
    if (!key) return;
    setActiveTab(key);
  };

  return (
    <div data-component={ component || Tabs.name }>
      <div className="relative flex justify-center">
        { /* Tab Navigation */ }
        <nav className={`flex ${isPageableTab(tabs[0]) ? "justify-center" : "overflow-auto"} pb-4 w-fit px-4`}>
          <List
            direction="row"
            columns={isPageableTab(tabs[0]) ? { default: "default", md: "noWrap" } : "noWrap"}
            justify="center"
            className="space-x-2"
          >
            { tabs.map((tab: TTabType, index: number) => (
              <ListItem key={ tab.key } className={isPageableTab(tabs[0]) ? "mb-2 md:mb-0" : ""}>
                <button
                  data-index={ index }
                  ref={ (tabButton) => {
                    if (tabButton) {
                      tabButtons.current[index] = tabButton;
                    }
                  } }
                  id={ getButtonId(tab.key) }
                  aria-controls={ getContentId(tab.key) }
                  aria-selected={ isActiveTab(tab.key) }
                  role="tab"
                  onClick={ () => setActiveTab(tab.key) }
                  onKeyDown={ onButtonKeyDown }
                  tabIndex={ isActiveTab(tab.key) ? 0 : -1 }
                  data-active={isActiveTab(tab.key)}
                  className={ isDefaultButton(tab.title) ? `border border-solid border-dark-grey rounded-sm ${isPageableTab(tab) ? "px-2 py-1" : "px-4 py-1"} ${isActiveTab(tab.key) ? `bg-${getActiveTabTheme(tab)}` : "text-dark-grey"}` : "group" }
                >
                  { isDefaultButton(tab.title) ? (
                    isPageableTab(tab) ? (
                      <TextSubtitle tag="span" size={ 3 } className="uppercase">{ tab.title }</TextSubtitle>
                    ) : (
                      <TextTitle tag="span" size={3}>{ tab.title }</TextTitle>
                    )
                  ) : (
                    <>
                      { tab.title }
                    </>
                  ) }
                </button>
              </ListItem>
            )) }
          </List>
        </nav>
        <div className="absolute top-0 right-0 w-8 h-full bg-gradient-to-r from-transparent to-white"></div>
      </div>
      <div>
        { /* Tab Content with pageable navigation if the tab is pageable */ }
        { tabs.map((tab: TTabType, index: number) => {
          const content = isPageableTab(tab) ? (
            <PageableNavigation
              title={tab.Panel.title}
              subtitle={tab.Panel.subtitle}
              theme={getActiveTabTheme(tab)}
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={setActiveTabFromPanel}
              data-testid={`pageable-navigation-${tab.key}`}
            >
              { tab.Panel.Content }
            </PageableNavigation>
          ) : tab.Content;

          return (
            <div
              key={tab.key}
              id={getContentId(tab.key)}
              data-testid={`tab-content-${tab.key}`}
              data-content-active={isActiveTab(tab.key)}
              aria-labelledby={getButtonId(tab.key)}
              className={tabStateClasses(tab.key)}
              aria-hidden={!isActiveTab(tab.key)}
              tabIndex={isActiveTab(tab.key) ? 0 : -1}
              data-index={index}
              role="tabpanel"
            >
              { content }
            </div>
          );
        }) }
      </div>
    </div>
  );
};

export default Tabs;
