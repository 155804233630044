export const pageableTabButtonClasses = [
  "aspect-square",
  "h-fit",
  "rounded-full",
  "bg-black",
  "text-white",
  "border-2",
  "border-solid",
  "border-black",
  "disabled:bg-transparent",
  "disabled:cursor-not-allowed",
  "disabled:border-light-grey",
  "disabled:text-light-grey",
].join(" ");
