"use client";

import { FC, Suspense, useMemo } from "react";
import { useSearchParams } from "next/navigation";

import { IImageProps } from "apps/website/components/base/Image/Image";
import { Theme } from "apps/website/maps/Theme.map";

import SectionPreCancellationDiscount from "./SectionPreCancellationDiscountExperiment/SectionPreCancellationDiscount";
import SectionPreCancellationControl from "./PrecancellationSplashControl/SectionPreCancellationControl";

export type TPrecancellationSubscriptionState = "TRIAL" | "DEFAULT";

// TODO: Minimal props for a/b test
export interface ISectionPreCancellationSplash {
  theme?: Theme;
  image: IImageProps;
  subscriptionState?: TPrecancellationSubscriptionState;
}

const SuspendedSectionPreCancellationSplash: FC<ISectionPreCancellationSplash> = ({ theme, image, subscriptionState = "DEFAULT" }: ISectionPreCancellationSplash) => {
  const query = useSearchParams();

  const catName = useMemo(() => {
    if (query?.get("cat")) {
      return query.get("cat");
    }
    return "your cat";
  }, [ query ]);

  const eligible = query?.get("eligible") === "true";

  return eligible ? (
    <SectionPreCancellationDiscount
      theme={"brand"}
      image={image}
      catName={catName!}
    />
  ) : (
    <SectionPreCancellationControl
      theme={theme}
      image={image}
      catName={catName!}
      subscriptionState={subscriptionState}

    />
  );
};

export const SectionPreCancellationSplash: FC<ISectionPreCancellationSplash> = ({
  theme, image, subscriptionState,
}) => (
  <Suspense>
    <SuspendedSectionPreCancellationSplash theme={theme} image={image} subscriptionState={subscriptionState} />
  </Suspense>
);

export default SectionPreCancellationSplash;
